import { gql } from '@apollo/client'

export const LOG_IN = gql`
  mutation login($username: String!, $password: String!) {
    login(
      input: {
        provider: PASSWORD
        credentials: { username: $username, password: $password }
      }
    ) {
      authToken
      refreshToken
      user {
        id
      }
    }
  }
`

export const LOG_IN_WITH_GOOGLE = gql`
  mutation login($token: String!) {
    login(input: { provider: GOOGLE, oauthResponse: { code: $token } }) {
      authToken
      refreshToken
      user {
        id
      }
    }
  }
`

export const REGISTER = gql`
  mutation register(
    $username: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    registerUser(
      input: {
        username: $username
        firstName: $firstName
        lastName: $lastName
        email: $email
        password: $password
      }
    ) {
      user {
        auth {
          authToken
          refreshToken
        }
      }
      clientMutationId
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(input: { refreshToken: $refreshToken }) {
      authToken
    }
  }
`
