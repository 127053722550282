import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { toast } from 'react-toastify'
import T from 'prop-types'
import UAParser from 'ua-parser-js'

import { getRegistrationToken, requestPermission } from '../utils/firebase'
import { getNotif } from '../utils/window'

const apiUrl = process.env.GATSBY_WP_API_URL
const apiKey = process.env.GATSBY_WP_API_KEY

const baseUrl = `${apiUrl}/wp-json/fcm/pn`

const NotificationContext = createContext({})

export function NotificationProvider({ children }) {
  const [showPrompt, setShowPrompt] = useState(null)

  useEffect(() => {
    const notifAnswer = localStorage.getItem('notification-answer')

    if (notifAnswer !== 'denied') {
      const permission = getNotif().permission
      if (permission && permission !== 'granted') {
        setTimeout(() => setShowPrompt(true), 2500)
      }
    }
  }, [])

  const registerToken = useCallback(async (token) => {
    try {
      if (!token) {
        return
      }
      const deviceUuid =
        localStorage.getItem('notification-device-uuid') || crypto.randomUUID()
      const deviceInfo = new UAParser(navigator?.userAgent)?.getResult()
      const osVersion = `${deviceInfo.os.name}-${deviceInfo.os.version}`
      const browserVersion = `${deviceInfo.browser.name}-${deviceInfo.browser.version}`

      const payload = {
        device_token: token,
        device_uuid: deviceUuid,
        rest_api_key: apiKey,
        subscription: 'news',
        os_version: `${browserVersion}@${osVersion}`,
      }

      const result = await fetch(`${baseUrl}/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      const registration = await result.json()

      localStorage.setItem('notification-device-uuid', deviceUuid)

      toast('Vous êtes maintenant abonné aux notifications de Zone Critique', {
        type: 'success',
      })
    } catch (e) {
      toast(
        "Une erreur est survenue nous n'avons pas pu vous abonner aux notifications"
      )
    }
  }, [])

  const accept = useCallback(async () => {
    try {
      setShowPrompt(false)
      const permission = await requestPermission()

      if (permission === 'granted') {
        const token = await getRegistrationToken()
        await registerToken(token)
      }

      localStorage.setItem('notification-answer', 'granted')
    } catch (error) {}
  }, [registerToken])

  const deny = useCallback(() => {
    setShowPrompt(false)
    localStorage.setItem('notification-answer', 'denied')
  }, [])

  const value = {
    showPrompt,
    accept,
    deny,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}

NotificationProvider.propTypes = {
  children: T.node.isRequired,
}

export const useNotification = () => useContext(NotificationContext)

const Wrapper = ({ element }) => {
  return <NotificationProvider>{element}</NotificationProvider>
}

Wrapper.propTypes = {
  element: T.node.isRequired,
}

export default Wrapper
