import { toast } from 'react-toastify'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

import { getNotif } from './window'

export const firebaseConfig = {
  apiKey: 'AIzaSyDPcKKz2lLBnLLw1JD-KJQ4wDJdBJWEUpU',
  authDomain: 'zone-critique-3a7c7.firebaseapp.com',
  projectId: 'zone-critique-3a7c7',
  storageBucket: 'zone-critique-3a7c7.appspot.com',
  messagingSenderId: '872249864286',
  appId: '1:872249864286:web:5267b2b78d999dd55744cf',
  measurementId: 'G-3HYTYFNS7T',
}

let app
let messaging

if (typeof window !== 'undefined') {
  try {
    app = initializeApp(firebaseConfig)

    messaging = getMessaging(app)

    onMessage(messaging, (payload) => {
      console.info('Message received. ', payload)
      const { title, body, icon } = payload
      toast(`${title}\n${body}`, {
        icon,
      })
      // ...
    })
  } catch (error) {
    // user may be in a incognito window
  }
}

export async function requestPermission() {
  const notif = getNotif()
  if (notif.requestPermission) {
    return notif.requestPermission()
  }
  return null
}

export const getRegistrationToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.GATSBY_FCM_VAPID_KEY,
    })

    localStorage.setItem('notification-token', token)

    return token
  } catch (error) {
    return null
  }
}
