import { isAfter, set } from 'date-fns'

export const getFullname = (user) => `${user.firstName} ${user.lastName}`

export const getInitials = ({ firstName, lastName, email }) => {
  if (firstName && lastName) {
    return [firstName.charAt(0), lastName.charAt(0)].join('').toUpperCase()
  } else if (email) {
    return email.charAt(0).toUpperCase()
  } else {
    return ' '
  }
}

export const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email)
}

export const getSubscription = (user) => {
  const [year, month, day] = (user?.renouvellementAbo || '')
    .split('-')
    .map((str) => parseInt(str, 10))

  const endSubscription = year
    ? set(new Date(), {
        date: day,
        month: month - 1,
        year,
        hours: 23,
        minutes: 59,
        seconds: 59,
      })
    : null

  const now = new Date()

  const isSubscriber = isAfter(endSubscription, now)

  return { isSubscriber, endSubscription }
}
