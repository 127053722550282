import React from 'react'
import { createRoot } from 'react-dom/client'

import App from './src/components/app'

import './src/css/tailwind.css'
import 'react-toastify/dist/ReactToastify.css'

// https://stackoverflow.com/questions/77651939/getting-uncaught-error-minified-react-error-418-on-my-gatsby-production-build
export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = createRoot(container)
    root.render(element)
  }
}
export const wrapRootElement = ({ element, ...props }) => {
  return <App {...props}>{element}</App>
}
